"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const composition_api_1 = require("@nuxtjs/composition-api");
exports.default = () => {
    const times = (0, composition_api_1.reactive)(Array.from({ length: 48 }, (_, i) => {
        if (i % 2 === 0)
            return {
                hour: i / 2,
                minute: 0
            };
        else {
            return {
                hour: (i - 1) / 2,
                minute: 30
            };
        }
    }));
    return { times };
};
