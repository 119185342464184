import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-center","singleton":true,"iconPack":"mdi","keepOnHover":false,"fullWidth":true,"fitToScreen":true,"duration":1000,"closeOnSwipe":true,"className":"w-toast"})

const globals = [{"name":"appError","iconPack":"mdi","message":(payload) => {
      const alertIcon = `<svg style="width: 24px; height: 24px; margin-right: 8px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" viewBox="0 0 50 50" xml:space="preserve">
          <circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/>
          <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,34 25,25 34,16   "/>
          <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,16 25,25 34,34   "/>
          </svg>`;
      // if there is a message show it with the message
      return alertIcon + (payload?.message || payload || 'Đã có lỗi xảy ra');
    },"options":{"type":"error","duration":2500,"className":["w-toast","w-error-toast"],"icon":false}},{"name":"appSuccess","iconPack":"mdi","message":(payload) => {
      // if there is a message show it with the message
      const checkIcon = `<svg style="width: 24px; height: 24px; margin-right: 8px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" viewBox="0 0 50 50" xml:space="preserve">
        <circle style="fill:#25AE88;" cx="25" cy="25" r="25"/>
        <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/>
        </svg>`;
      return checkIcon + (payload?.message || payload || 'Đã có lỗi xảy ra');
    },"options":{"type":"success","duration":2500,"className":["w-toast","w-success-toast"],"icon":false}},{"name":"appWarning","iconPack":"mdi","message":(payload) => {
      // if there is a message show it with the message
      const warningIcon = `<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" style="margin-right: 8px" width="24px" height="24px" viewBox="0 0 22 22" id="svg2" version="1.1" inkscape:version="0.92.1 r15371" sodipodi:docname="dark_warning.svg">
        <defs id="defs4"><linearGradient gradientUnits="userSpaceOnUse" y2="2" x2="16" y1="29.999973" x1="16" id="linearGradient4144" xlink:href="#linearGradient4155" inkscape:collect="always" gradientTransform="matrix(1.66667 0 0 -1.66667 -15.667 1064.696)"/><linearGradient inkscape:collect="always" id="linearGradient4155">
        <stop style="stop-color:#fcd994;stop-opacity:1" offset="0" id="stop4157"/>
        <stop style="stop-color:#fff6e1;stop-opacity:1" offset="1" id="stop4159"/>
        </linearGradient>
        </defs>
        <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:zoom="22.4" inkscape:cx="-8.3436117" inkscape:cy="10.155389" inkscape:document-units="px" inkscape:current-layer="layer1" showgrid="true" inkscape:showpageshadow="false" borderlayer="true" inkscape:window-width="1884" inkscape:window-height="1051" inkscape:window-x="0" inkscape:window-y="0" inkscape:window-maximized="1" units="px">
        <sodipodi:guide position="2,20.000017" orientation="18,0" id="guide4085" inkscape:locked="false"/>
        <sodipodi:guide position="2,2.0000174" orientation="0,18" id="guide4087" inkscape:locked="false"/>
        <sodipodi:guide position="20,2.0000174" orientation="-18,0" id="guide4089" inkscape:locked="false"/>
        <sodipodi:guide position="20,20.000017" orientation="0,-18" id="guide4091" inkscape:locked="false"/>
        <sodipodi:guide position="3,19.000017" orientation="16,0" id="guide4093" inkscape:locked="false"/>
        <sodipodi:guide position="3,3.0000174" orientation="0,16" id="guide4095" inkscape:locked="false"/>
        <sodipodi:guide position="19,3.0000174" orientation="-16,0" id="guide4097" inkscape:locked="false"/>
        <sodipodi:guide position="19,19.000017" orientation="0,-16" id="guide4099" inkscape:locked="false"/>
        <inkscape:grid type="xygrid" id="grid4101"/>
        </sodipodi:namedview>
        <metadata id="metadata7">
        <rdf:RDF>
        <cc:Work>
        <dc:format>image/svg+xml</dc:format>
        <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
        <dc:title/>
        <dc:creator>
        <cc:Agent>
        <dc:title>Timothée Giet</dc:title>
        </cc:Agent>
        </dc:creator>
        <dc:date>2017</dc:date>
        <cc:license rdf:resource="http://creativecommons.org/licenses/by-sa/4.0/"/>
        </cc:Work>
        <cc:License rdf:about="http://creativecommons.org/licenses/by-sa/4.0/">
        <cc:permits rdf:resource="http://creativecommons.org/ns#Reproduction"/>
        <cc:permits rdf:resource="http://creativecommons.org/ns#Distribution"/>
        <cc:requires rdf:resource="http://creativecommons.org/ns#Notice"/>
        <cc:requires rdf:resource="http://creativecommons.org/ns#Attribution"/>
        <cc:permits rdf:resource="http://creativecommons.org/ns#DerivativeWorks"/>
        <cc:requires rdf:resource="http://creativecommons.org/ns#ShareAlike"/>
        </cc:License>
        </rdf:RDF>
        </metadata>
        <g inkscape:label="Capa 1" inkscape:groupmode="layer" id="layer1" transform="translate(0 -1030.362)">
        <path style="fill:#ffc35a;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="m11 1032.362-10 18h20zm0 2 8 15H3z" id="path839" inkscape:connector-curvature="0" sodipodi:nodetypes="cccccccc"/>
        <path style="fill:#373737;fill-opacity:.94117647;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M10 1046.362h2v2h-2z" id="path844" inkscape:connector-curvature="0"/>
        <path style="fill:#373737;fill-opacity:.94117647;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M10 1045.362h2v-6h-2z" id="path846" inkscape:connector-curvature="0" sodipodi:nodetypes="ccccc"/>
        </g>
        </svg>`;
      return warningIcon + (payload?.message || payload || 'Đã có lỗi xảy ra');
    },"options":{"type":"warning","duration":2500,"className":["w-toast","w-warning-toast"],"icon":false}},{"name":"appInfo","iconPack":"mdi","message":(payload) => {
      // if there is a message show it with the message
      const infoIcon = `<svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 8px" width="24px" height="24px" viewBox="0 0 60 60"><defs><style>
        .cls-1 {
          fill: #5b75a0;
          fill-rule: evenodd;
        }
        </style></defs><path class="cls-1" d="M1050,360a30,30,0,1,1,30,30A30,30,0,0,1,1050,360Zm30,18a4,4,0,0,1-4-4V358a4,4,0,0,1,8,0v16A4,4,0,0,1,1080,378Zm0-36a4,4,0,1,1-4,4A4,4,0,0,1,1080,342Z" id="info" transform="translate(-1050 -330)"/></svg>`;
      return infoIcon + (payload?.message || payload || 'Đã có lỗi xảy ra');
    },"options":{"type":"info","duration":2500,"className":["w-toast","w-info-toast"],"icon":false}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
