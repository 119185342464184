"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRGB = void 0;
function hexToRGB(hex) {
    const bigint = parseInt(hex.value, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}
exports.hexToRGB = hexToRGB;
