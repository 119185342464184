"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable import/named */
const preferences_1 = require("@capacitor/preferences");
const usePreferences = () => {
    const config = (options) => preferences_1.Preferences.configure(options);
    const get = (options) => preferences_1.Preferences.get(options);
    const remove = (options) => preferences_1.Preferences.remove(options);
    const clear = () => preferences_1.Preferences.clear();
    const set = (options) => preferences_1.Preferences.set(options);
    const getKeys = () => preferences_1.Preferences.keys();
    const migrate = () => preferences_1.Preferences.migrate();
    const removeOld = () => preferences_1.Preferences.removeOld();
    return {
        get,
        Preferences: preferences_1.Preferences,
        config,
        remove,
        clear,
        set,
        getKeys,
        migrate,
        removeOld
    };
};
exports.default = usePreferences;
