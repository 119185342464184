"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebouncedRef = void 0;
const composition_api_1 = require("@nuxtjs/composition-api");
// docs: https://vuejs.org/api/reactivity-advanced.html#customref
function useDebouncedRef(value, delay = 200) {
    let timeout;
    return (0, composition_api_1.customRef)((track, trigger) => {
        return {
            get() {
                track();
                return value;
            },
            set(newValue) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    value = newValue;
                    trigger();
                }, delay);
            }
        };
    });
}
exports.useDebouncedRef = useDebouncedRef;
