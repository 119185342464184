"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLog = void 0;
const composition_api_1 = require("@nuxtjs/composition-api");
function useLog(option) {
    const { route } = (0, composition_api_1.useContext)();
    const logs = (0, composition_api_1.ref)([]);
    // useStorage(option?.key || 'debug-log', []) as unknown as Ref<
    //   ILog[]
    // >
    const log = (input) => {
        input.time = new Date().toISOString();
        if (!input.level)
            input.level = 'debug';
        input.route = route.value.path;
        logs.value.push(input);
        if (logs.value.length > ((option === null || option === void 0 ? void 0 : option.max) || 250)) {
            logs.value.shift();
        }
    };
    const clear = () => {
        logs.value = [];
    };
    return {
        log,
        logs,
        clear
    };
}
exports.useLog = useLog;
