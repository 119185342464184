"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorIsBright = void 0;
const hex_to_rgb_1 = require("./hex-to-rgb");
function ColorIsBright(hex) {
    const { r, g, b } = (0, hex_to_rgb_1.hexToRGB)(hex);
    if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
        return true;
    }
    else
        return false;
}
exports.ColorIsBright = ColorIsBright;
