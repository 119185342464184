import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2a6b2f56"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WPermissionsProvider: require('/usr/src/app/node_modules/@wellcare/vue-component/components/global/permissions-provider.vue').default})
